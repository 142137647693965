import { FilterGroups } from "@module/OverviewPage/FilterGroups.ts";
import { OverviewPageView } from "@module/OverviewPage/OverviewPageView.ts";
import { UrlHandler } from "@module/Shared/UrlHandler.ts";
import { sortAdverts } from "@module/OverviewPage/sortAdverts.ts";

const urlHandler: UrlHandler = new UrlHandler();

new FilterGroups(
    new OverviewPageView(urlHandler),
    urlHandler
);

sortAdverts();
